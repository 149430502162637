@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .overflow-wrappers {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  .zoom-85 {
    zoom: 0.85;
  }

  .zoom-115 {
    zoom: 1.15;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    @apply bg-input text-text-primary;
    -webkit-text-fill-color: #f6f7f9 !important;
    caret-color: #f6f7f9 !important;
    -webkit-box-shadow: 0 0 0 1000px #f6f7f933 inset !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
  }
}

:root {
  --rt-opacity: 1 !important;
  --height-header: 64px;
  --height-header-mobile: 48px;

  --toastify-color-progress-success: #00B147;
  --toastify-color-progress-warning: #FFDF8B;
  --toastify-color-progress-light: #FFDF8B !important;
  --toastify-color-progress-error: #F64848;
}

html {
  font-size: 14px;
}

h2,
h3 {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  pointer-events: none;
}

svg {
  vertical-align: top;
}

.cutoff:before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 5' style='background-color: black;'%3E%3Cpolygon points='0,5 5.5,0 11,5' fill='white' /%3E%3C/svg%3E");
  background-repeat: repeat-x;
}

.hide-arrow[type='number']::-webkit-inner-spin-button,
.hide-arrow[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#sport_div_iframe {
  height: 100%;
  min-height: 90vh;
  overflow: auto;
}

#sport_div_iframe > iframe {
  height: 100%;
}

#betslip-container-element {
  z-index: 9999 !important;
}
iframe#launcher,
iframe#launcher + iframe {
  visibility: hidden;
}

@media screen and (min-width: 980px) {
  iframe#launcher,
  iframe#launcher + iframe {
    visibility: visible;
  }
}

.bullet,
.bullet > li {
  list-style: unset;
  margin: unset;
}

.number,
.number > li {
  list-style: auto;
  margin: unset;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.email,
.phone {
  text-decoration: none;
  -webkit-text-decoration-skip: none;
}

.react-tooltip {
  z-index: 1;
}

.Toastify__progress-bar--bg {
  background: none !important;
}

.Toastify__progress-bar--wrp {
  border-radius: 16px !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.blur-backgroud {
  background: rgba(25 26 31 0.8);
  backdrop-filter: blur(10px);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-scrollbar ::-webkit-scrollbar {
  background-color: #191a1fb2;
  width: 8px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #5e61654d;
  border-radius: 8px;
}

.swiper-button-disabled {
  display: none !important;
}

.sidebar::-webkit-scrollbar {
  display: none;
}
